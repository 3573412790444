<template>
<div>
  <v-col cols="12" md="10" lg="8" class="mt-5 mx-auto">

    <v-card elevation="0" class="pa-md-5 font-papyrus">
      <v-col class="pa-5">
        
          <h1>Conditions générales d'utilisation</h1>
          <br>
          En vigueur au 14/05/2023
          <br><br>

          <v-divider></v-divider>
          
          <br><br>

          <div style="font-size:13px;">
            Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique
            des modalités de mise à disposition du site et des services, et de définir les
            conditions d’accès et d’utilisation des services par « l'Utilisateur ».
            <br><br>
            Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
            <br><br>
            Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des
            présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le Formulaire d’inscription, chaque
            utilisateur accepte expressément les présentes CGU lorsqu'il s'inscrit.
            <br><br>
            En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer
            à l'accès des services proposés par le site.
            <br><br>
            lamonnaiepirate.net se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes
            CGU.

            <br><br>
            <b>Article 1 : Les mentions légales</b>
            <br><br>
            L’édition et la direction de la publication du site lamonnaiepirate.net est assurée par : <br>
            <v-btn elevation="0" class="mt-2" @click="showCoordonnee = true">
              Afficher les coordonnées
            </v-btn>
            
            <br><br>
            L'hébergeur du site lamonnaiepirate.net est la société OVH, dont le siège social est situé au : <br>
            2 rue Kellermann - 59100 Roubaix - France
            <br><br>

            <b>Article 2 : Accès au site</b>
            <br><br>
            Le site lamonnaiepirate.net met à disposition de ses Utilisateurs une application de jeu en ligne,
            où l'Utilisateur est invité à jouer le rôle d'un pirate dans un monde fictif et fantaisiste.
            <br><br>
            Le site lamonnaiepirate.net permet à l'Utilisateur un accès gratuit à l'ensemble de ses services,
            à l'exception des parties de jeux multijoueurs, réservées aux Utilisateurs ayant participés au financement du jeu
            depuis la page "Devenir membre d'équipage".
            <br><br>
            Le reste du site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet.
            <br><br>
            Certaines parties du jeu ne sont accessibles que pour les Utilisateurs inscrits et connectés à leur compte personnel.
            <br><br>
            La création de ce compte est 100% gratuit.
            <br><br>
            Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion
            Internet, etc.) sont à sa charge.
            <br><br>
            Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot
            de passe qui lui seront communiqués après son inscription.
            <br><br>
            Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se
            rendant à la page dédiée sur son espace personnel.
            <br><br>
            Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site
            ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas
            la responsabilité de lamonnaiepirate.net. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur
            de toute interruption ou suspension de service, même sans préavis.
            <br>
            L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de
            l’éditeur communiqué à l’Article 1.
            <br><br>
            L'éditeur de lamonnaiepirate.net prend toutes les précautions nécessaires pour que le système puisse être rétabli
            rapidement même en cas de dysfonctionnement majeur.

            <br><br>
            <b>Article 3 : Collecte des données</b>
            <br><br>
            Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect
            de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers
            et aux libertés. 
            <br><br>
            En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit
            d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur
            exerce ce droit via son espace personnel.

            <br><br>
            <b>Article 4 : Propriété intellectuelle</b>
            <br><br>
            Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son...) font l'objet
            d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
            L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des
            différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé,
            toute utilisation à des fins commerciales et publicitaires est strictement interdite.
            <br><br>
            Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation
            expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’Article L 335-2
            et suivants du Code de la propriété intellectuelle.
            
            <br><br>
            <b>Article 5 : Responsabilité</b>
            <br><br>
            Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle.
            Malgré des mises à jour régulières, le site lamonnaiepirate.net ne peut être tenu responsable de la modification
            des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut
            être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.
            <br><br>
            L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle
            que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de
            passe. Le site décline toute responsabilité.
            <br><br>
            Le site lamonnaiepirate.net ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter
            l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au
            téléchargement provenant de ce site.
            <br><br>
            La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et
            insurmontable d'un tiers.

            <br><br>
            <b>Article 6 : Liens hypertextes</b>
            <br><br>
            Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces
            liens, il sortira du site lamonnaiepirate.net. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles
            aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
            
            <br><br>
            <b>Article 7 : Cookies</b>
            <br><br>
            L’Utilisateur est informé que lors de ses visites sur le site, un cookie est utilisé pour gérer la connexion à son compte personnel.
            <br><br>
            Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de
            l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site lamonnaiepirate.net. 
            Nous n'utilisons pas ces cookie pour tracer votre activité sur le site. Aucune information sensible n'est stockée dans nos cookies.
            <br><br>
            En naviguant sur le site, L’Utilisateur accepte cet usage.
            <br><br>
            A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui
            être refusées.
            L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son
            logiciel de navigation.
            <br><br>
            <b>Article 8 : Publication par l’Utilisateur</b>
            <br><br>
            Le site permet aux membres de publier les contenus suivants : des annonces (texte, images), et des messages.
            Dans ses publications, le membre s’engage à respecter les règles de bonne
            conduite de l’internet et les règles de droit en vigueur.
            <br><br>
            Le site peut exercer une modération sur les publications et se réserve le droit de refuser leur mise en
            ligne, sans avoir à s’en justifier auprès du membre.
            <br><br>
            Le membre reste titulaire de l’intégralité de ses droits de propriété intellectuelle. Mais en publiant une
            publication sur le site, il cède à la société éditrice le droit non exclusif et gratuit de diffuser sa publication, 
            pour la durée de la propriété intellectuelle. Le Membre cède notamment le droit d'utiliser sa publication sur internet et sur les
            réseaux de téléphonie mobile.
            <br><br>
            Tout contenu mis en ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur s'engage à ne
            pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout
            recours en justice engagé par un tiers lésé contre le site sera pris en charge par l'Utilisateur.
            Le contenu de l'Utilisateur peut être à tout moment supprimé par le site, sans préavis.
            <br><br>
            Chaque Membre peut signaler des annonces inapropriées aux modérateurs via l'interface de la plateforme.
            <br><br>
            <b>Article 9 : Modération du contenu publié</b>
            <br><br>
            Les administrateur de la plateforme se réserve le droit 
            de désactiver les annonces qui contiendraient l'un de contenus suivants : (liste non-exaustive)
            <br>
            - Contenu illégal (drogue, arme, contre-façon, etc)<br>
            - Contenu violent ou pornographique <br>
            - Arnaque, escroquerie, etc
            <br><br>
            Ainsi que tout autres contenus, qui pourrait nuire à la réputation ou à l'image de la plateforme.
            <br><br>
            Les contenus sont surveillés et modérés régulièrement par le/les administrateurs de la plateforme,
            et les utilisateurs peuvent signaler eux-mêmes les annonces qui leur semblent inapropriées.
            <br><br>
            Les administrateurs s'appuient sur les signalements des utilisateurs, 
            pour modérer au mieux l'ensemble des annonces publiées.

            <br><br>
            <b>Article 10 : Droit applicable et juridiction compétente</b>
            <br><br>
            La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un
            litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.
            Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux
            coordonnées inscrites à l’Article 1.
          </div>
      </v-col>
    </v-card>

    <br><br><br><br>
  </v-col>

  <v-dialog v-model="showCoordonnee" max-width="700">
    <v-card outlined v-if="showCoordonnee">
      <v-toolbar color="transparent" elevation="0">
        Coordonnées
        <v-spacer/>
        <v-btn fab outlined small @click="showCoordonnee=false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-col cols="12">
      <!--   <v-card-subtitle class="pb-0"><b>to</b> : {{ $store.state.app.dialogMailDev.to }}</v-card-subtitle>
        <v-card-subtitle><b>objet</b> : {{ $store.state.app.dialogMailDev.subject }}</v-card-subtitle> -->
        <v-card-text class="px-3 pt-5">
          Samuel Bellamy, domicilié au : 
          <br>
          Navire Whyda<br>
          7 Cours des Dames<br>
          17000 La Rochelle
          <br><br>
          Adresse e-mail : samuel.bellamy.prt@protonmail.com.
        </v-card-text>
      </v-col>
      <v-toolbar color="transparent" elevation="0">
        <v-spacer/>
        <v-btn elevation="0" @click="showCoordonnee=false">OK</v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
  <Footer/>
</div>
</template>

<style></style>

<script>

import Footer from '@/components/main-layout/Footer.vue'

export default {
  components: { Footer },
  data: () => ({
    showCoordonnee: false
    
  }),
  async mounted(){
    
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
